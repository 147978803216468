import React, { useEffect, useState } from "react";
import "./warrantTerms.css";
import Select from "react-select";
import { get, round } from "lodash";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { Link } from "react-router-dom";
import WarrantTermsModal from "../../../components/common/warrantTermsModal";
import TermsChart from "../../../components/Charts/TermsChart";
import ReactTooltip from "react-tooltip";
import LangContext from "../../../components/common/context/lang-context";
import { useContext } from "react";
import { languageText } from "../../../languageText/dwTerms";
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import WarrantCalculatorSideBars from '../../../components/common/Sidebar/WarrantCalculatorSideBar'
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"


const WarrantTerms = () => {
  

  const api = new API();
  const langContext = useContext(LangContext);
  const [symbolList, setSymbolList] = useState([]);
  const [Livedata, setLivedata] = useState([]);
  const [ric, setRic] = useState("");
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("D");
  const [chartDataArr, setChartDataArr] = useState([]);
  const [ricAndCode, setRicAndCode] = useState("");
  const [qid, setQid] = useState(Date.now());
  const [display, setDisplay] = useState("none");
  const [indeIv, setIndeIv] = useState("");
  const [disclaimerDefination, setDisclaimerDefination] = useState("");
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  useEffect(() => {
    getWarrantTermsSelect();
  },[]);

  useEffect(() => {
    getWarrantToolsLiveMatrix()
  }, [ric])

  useEffect(() => {
    if (ricAndCode != "") getWarrantChartData();
  }, [activeTab,ricAndCode]);

  const getWarrantTermsSelect = async () => {
    var data = {
      qid,
    };
    var LiveIndexSymbolResult = await api.get(config.homeLiveIndexFuturesSymbol, true,data);
    var liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", []);
    var liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ security_code, ric: value }) => ({
        label: `${security_code}`,
        value,
        security_code,
      })
    );
    setSymbolList(liveIndexSymbolData);
    handlechange(liveIndexSymbolData);
  };

  const handlechange = (e) => {
    var queryString = window.location.pathname.split("/");    
    var splitData = queryString[3];
    var ric = ""
    if(splitData != undefined && e.length > 0) {
      var ricData = e.filter((j) => splitData === j.security_code);          
      var finalRicVal = ricData[0].value;        
      setRic(finalRicVal);
    }else{
      ric = $.cookie('TOOLS_PREF_RIC_TH');
      if(ric == "" || ric === null || ric === undefined){
        setRic(e[0]?.value);
      }else{
        setRic(ric);
      }
    }
  };

  const getWarrantToolsLiveMatrix = async () => {
    let data = {
      type: "warrantdata",
      ric: ric,
      qid: qid,
    };
    let LiveIndexSymbolResult = await api.get(
      config.marketTurnOver,
      true,
      data
    );
    setLivedata(LiveIndexSymbolResult[ric]);
    if(LiveIndexSymbolResult[ric]?.underlying_ticker=="HSI"||LiveIndexSymbolResult[ric]?.underlying_ticker=="S&P500"||
            LiveIndexSymbolResult[ric]?.underlying_ticker=="HSTECH"||LiveIndexSymbolResult[ric]?.underlying_ticker=="HSCEI"||LiveIndexSymbolResult[ric]?.underlying_ticker=="SET50"||LiveIndexSymbolResult[ric]?.underlying_ticker=="SET50*"||
            LiveIndexSymbolResult[ric]?.underlying_ticker=="DJI"||LiveIndexSymbolResult[ric]?.underlying_ticker=="NDX"){
        if(LiveIndexSymbolResult[ric]?.index_iv != null || LiveIndexSymbolResult[ric]?.index_iv != undefined){
            var iv = Number(LiveIndexSymbolResult[ric]?.index_iv).toFixed(1);
            setIndeIv(iv+"%");
            setDisplay("initial")
        }else{
            setIndeIv("N/A");
            setDisplay("initial")
        }
    }else{
      setIndeIv("");
      setDisplay("none")
    }
    let underlyingRic = LiveIndexSymbolResult[ric]?.underlying_ric;  
    setRicAndCode(underlyingRic);
    if(LiveIndexSymbolResult[ric]?.underlying_currency!=null ){
      document.querySelectorAll(".ccy").forEach((e) => {
        e.innerHTML = LiveIndexSymbolResult[ric]?.underlying_currency
      })
    }else{
      document.querySelectorAll(".ccy").forEach((e) => {
        e.innerHTML = ''
      })
    }
  };

  const getSelectedValue = (e) => {
    const { value, label, security_code } = e;     
    setRic(e.value); 
    window.history.pushState("", "", `/tools/dwterms/${e.security_code}`)  
    setActiveTab("D")
  };

  const getWarrantChartData = async () => {
    let data = {
      TYPE: "JSON",
      RIC: ricAndCode + ";" + ric,
      PERIODICITY: activeTab,
      d: qid,
    };
    let WarrantChartResult = await api.get(config.termAlert, true, data);
    setChartDataArr(WarrantChartResult);
  };

  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  };

  const disclaimerDefinationFun = () => {
    if(disclaimerDefination == ""){
      setDisclaimerDefination("in")
    }else{
      setDisclaimerDefination("")
    }
  }

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign: 'center', // Center-align the text input
    }),
    input:(provided)=>({
      ...provided,
      width:"100%",
      display:"flex "
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'center', // Center-align the selected value
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#007fff" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        border: isFocused ? "1px solid #003eff" : null,
        textAlign: "center",
        fontSize: "21px",
        padding: siteLang == "eng" ? "0px 10px" : "3px 10px",
        fontFamily: "'DBHeaventLight','DBHeavent'",
        cursor: "pointer",
      };
    },
  };

  return (
    <div id="dwterms" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home">
                {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 w-100-md">
            <div className="section">
              <div className="page-header">
                <h1>
                  {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                </h1>
              </div>
              <p style={{ marginBottom: "10px" }}>
                {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
              </p>
              <div id="powersearch">
                <Select
                className="select_align_center"
                  placeholder={siteLang == "eng" ? languageText.selectSymbolPlaceholderEn : languageText.selectSymbolPlaceholder}
                  onChange={(e) => getSelectedValue(e)}
                  options={symbolList}
                  styles={colourStyles}
                  value={"เลือกชื่อย่อ DW"}
                />
              </div>
              <div id="dwsummary" style={{ position: "relative" }}>
                <div id="ticker" className="tickerbar bgcolor-03">
                  {Livedata?.ticker}
                </div>
                <table id="pricetbl" className="uppertbl">
                  <colgroup>
                    <col style={{ backgroundColor: "#E7E8E8", padding: 3 }} />
                    <col style={{ backgroundColor: "#F5F6F7", padding: 3, textAlign: "left",}} />
                    <col style={{ backgroundColor: "#F5F6F7", padding: 3, textAlign: "right",}} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td className="uppertbl-col-bid" rowSpan={2}>
                        <div className="bidprice lbl">
                          {siteLang == "eng" ? languageText.bidPriceEn : languageText.bidPrice}
                        </div>
                        <div id="bidval" className="bidprice val">
                          {Livedata?.BID}
                        </div>
                      </td>
                      <td className="uppertbl-col-chglbl">
                        {siteLang == "eng" ? languageText.changeEn : languageText.change}
                      </td>
                      <td id="bidchg" className={ Livedata?.NETCHNG_1 === 0 || Livedata?.NETCHNG_1 == "-" ? "uppertbl-col-chgval val" : Livedata?.NETCHNG_1 > 0 ? "uppertbl-col-chgval val up" : "uppertbl-col-chgval val down"}>
                        {Livedata?.NETCHNG_1 > 0 ? "+" : ""}
                        {Livedata?.NETCHNG_1 === "" || Livedata?.NETCHNG_1 === null ? "-" : Livedata?.NETCHNG_1 == 0 ? " 0" : Livedata?.NETCHNG_1}
                      </td>
                    </tr>
                    <tr>
                      <td className="uppertbl-col-pchglbl">
                        {siteLang == "eng" ? "%"+languageText.changeEn : "%"+languageText.change}
                      </td>
                      <td id="bidpchg" className={ Livedata?.pct_dis === 0 || Livedata?.pct_dis == "-" ? "uppertbl-col-chgval val" : Livedata?.pct_dis > 0 ? "uppertbl-col-chgval val up" : "uppertbl-col-chgval val down"}>
                        {Livedata?.pct_dis > 0 ? "+" : ""}
                        {isNaN(Livedata?.pct_dis) ? "-" : Livedata?.pct_dis === 0 ? "0" : Number(Livedata?.pct_dis).toFixed(2)}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table id="periodtab" className="ctab">
                <tbody>
                  <tr>
                    <td onClick={() => setActiveTab("D")} className={`ctab-item ${isActive("D")}`} data-value="D">
                      1D
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td onClick={() => setActiveTab("W")} className={`ctab-item ${isActive("W")}`} data-value="W">
                      1W
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td onClick={() => setActiveTab("M")} className={`ctab-item ${isActive("M")}`} data-value="M">
                      1M
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td onClick={() => setActiveTab("Y")} className={`ctab-item ${isActive("Y")}`} data-value="Y">
                      MAX
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="chart-legend">
                <div className="row hidden-xs">
                  <div className="chart-legend-left col-sm-6">
                    <div className="chart-legend-item">
                      {siteLang == "eng" ? languageText.dwBidPriceEn : languageText.dwBidPrice}
                      <span className="chart-legend-bar warrant" />
                    </div>
                  </div>
                  <div className="chart-legend-right col-sm-6">
                    <div className="chart-legend-item">
                      {siteLang == "eng" ? languageText.underlyingPriceEn : languageText.underlyingPrice}(<span className="ccy"></span>)
                      <span className="chart-legend-bar underlying" />
                    </div>
                  </div>
                </div>
                <div className="row visible-xs">
                  <table className="chart-legend-tbl">
                    <tbody>
                      <tr>
                        <td>
                          {siteLang == "eng" ? languageText.dwBidPriceEn : languageText.dwBidPrice}
                        </td>
                        <td>
                          <span className="chart-legend-bar warrant" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {siteLang == "eng" ? languageText.underlyingPriceEn : languageText.underlyingPrice}(<span className="ccy"></span>)
                        </td>
                        <td>
                          <span className="chart-legend-bar underlying" />
                        </td>                                         
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <TermsChart dataArr={chartDataArr} period={activeTab}  Livedata={Livedata}/>
              <div className="prior_table" id="srtable">
                <table>
                  <tbody>
                    <tr>
                      <th className="srtable-leftlbl bgcolor-01">
                        {siteLang == "eng" ? languageText.priorEn : languageText.prior}
                      </th>
                      <td className="srtable-leftval bgcolor-01 val HST_CLOSE">
                        {Livedata?.HST_CLOSE}
                      </td>
                      <th className="srtable-rightlbl bgcolor-02">
                        {siteLang == "eng" ? languageText.lastTradeEn : languageText.lastTrade}
                      </th>
                      <td className="srtable-rightval bgcolor-02 val TRDPRC_1">
                        {Livedata?.TRDPRC_1}
                      </td>
                    </tr>
                    <tr>
                      <th className="srtable-leftlbl">
                        {siteLang == "eng" ? languageText.openEn : languageText.open}
                      </th>
                      <td className="srtable-leftval val OPEN_PRC">
                        {Livedata?.OPEN_PRC}
                      </td>
                      <th className="srtable-rightlbl">
                        {siteLang == "eng" ? languageText.tradedVolumeEn : languageText.tradedVolume}
                      </th>
                      <td className="srtable-rightval val ACVOL_1_SCALED">
                        {Livedata?.ACVOL_1}
                      </td>
                    </tr>
                    <tr>
                      <th className="srtable-leftlbl bgcolor-01">
                        {siteLang == "eng" ? languageText.highEn : languageText.high}
                      </th>
                      <td className="srtable-leftval bgcolor-01 val HIGH_1">
                        {Livedata?.HIGH_1}
                      </td>
                      <th className="srtable-rightlbl bgcolor-02">
                        {siteLang == "eng" ? languageText.tradedValueTHBEn : languageText.tradedValueTHB}
                      </th>
                      <td className="srtable-rightval bgcolor-02 val TURNOVER_SCALED">
                        {Livedata?.TURNOVER ? Livedata?.TURNOVER : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className="srtable-leftlbl">
                        {siteLang == "eng" ? languageText.lowEn : languageText.low}
                      </th>
                      <td className="srtable-leftval val LOW_1">
                        {Livedata?.LOW_1}
                      </td>
                      <th className="srtable-rightlbl">
                        {siteLang == "eng" ? languageText.underlyingBidPriceEn : languageText.underlyingBidPrice}
                      </th>
                      <td className="srtable-rightval val underlying_price">
                        {Livedata?.underlying_price}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="indtable" style={{ position: "relative" }}>
                <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
                  Indicator - <span className="val ticker">{Livedata?.ticker}</span>
                </h3>
                <table className="table table-closed table-striped visible-md visible-lg">
                  <tbody>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermUnderlyingTitle"}>
                          {siteLang == "eng" ? languageText.underlyingTitleEn : languageText.underlyingTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermUnderlyingTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.underlyingTitleEn : languageText.underlyingTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.underlyingTitleTooltipEn : languageText.underlyingTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val underlying_ticker">
                        {Livedata?.underlying_ticker}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermEffectiveGearingTitle"}>
                          {siteLang == "eng" ? languageText.effectiveGearingTitleEn : languageText.effectiveGearingTitle} (x)
                        </span>
                        <ReactTooltip
                          id={"dwTermEffectiveGearingTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.effectiveGearingTitleEn : languageText.effectiveGearingTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.effectiveGearingTitleTooltip1En : languageText.effectiveGearingTitleTooltip1}
                              <Link to="/eduhtml/effectivegearing">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.effectiveGearingTitleTooltip2En : languageText.effectiveGearingTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val effective_gearing">
                        {isNaN(Livedata?.effective_gearing) ? "N/A" : Number(Livedata?.effective_gearing).toFixed(1)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermHistoricalTitle"}>
                          {siteLang == "eng" ? languageText.historicalTitleEn : languageText.historicalTitle} (%) (90 Day)
                        </span>
                        <ReactTooltip
                          id={"dwTermHistoricalTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.historicalTitleEn : languageText.historicalTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.historicalTitleTooltipEn : languageText.historicalTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val volatility_90d">
                        {Number(Livedata?.volatility_90d).toFixed(1)}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermDeltaTitle"}>
                          {siteLang == "eng" ? languageText.deltaTitleEn : languageText.deltaTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermDeltaTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>{siteLang == "eng" ? languageText.deltaTitleEn : languageText.deltaTitle}:</strong>
                              {siteLang == "eng" ? languageText.deltaTitleTooltip1En : languageText.deltaTitleTooltip1}
                              <Link to="/eduhtml/delta">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.deltaTitleTooltip2En : languageText.deltaTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta">
                        {isNaN(Number(Livedata?.delta)) ? "N/A" : Number(Livedata?.delta).toFixed(1)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermTypeTitle"}>
                          {siteLang == "eng" ? languageText.typeTitleEn : languageText.typeTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermTypeTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.typeTitleEn : languageText.typeTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.typeTitleTooltipEn : languageText.typeTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val type">
                        {Livedata?.type}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermDeltaPerDwTitle"}>
                          {siteLang == "eng" ? languageText.deltaPerDwTitleEn : languageText.deltaPerDwTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermDeltaPerDwTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.deltaPerDwTitleEn : languageText.deltaPerDwTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.deltaPerDwTitleTooltip1En : languageText.deltaPerDwTitleTooltip1}
                              <Link to="/eduhtml/delta" target="_blank">
                                {siteLang == "eng"? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.deltaPerDwTitleTooltip2En : languageText.deltaPerDwTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta_per_wrnt">
                        {isNaN(Number(Livedata?.delta_dw)) ? "N/A" : Number(Livedata?.delta_dw).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermIssuerTitle"}>
                          {siteLang == "eng" ? languageText.issuerTitleEn : languageText.issuerTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermIssuerTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.issuerTitleEn : languageText.issuerTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.issuerTitleTooltipEn : languageText.issuerTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val issuer_name">
                        {Livedata?.issuer == null ? "-" : Livedata?.issuer}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermSensitivityTitle"}>
                          {siteLang == "eng" ? languageText.sensitivityTitleEn : languageText.sensitivityTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermSensitivityTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.sensitivityTitleEn : languageText.sensitivityTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.sensitivityTitleTooltipEn : languageText.sensitivityTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val sensitivity">
                        {isNaN(Livedata?.sensitivity) ? "N/A" : Number(Livedata?.sensitivity).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermMostRecentTitle"}>
                          {siteLang == "eng" ? languageText.mostRecentTitleEn : languageText.mostRecentTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermMostRecentTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.mostRecentTitleEn : languageText.mostRecentTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.mostRecentTitleTooltip1En : languageText.mostRecentTitleTooltip1}
                              <Link to="/eduhtml/howarewarrantspriced">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.mostRecentTitleTooltip2En : languageText.mostRecentTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val TRADE_DATE">
                        {Livedata?.TRADE_DATE == null ? "-" : Livedata?.TRADE_DATE}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermPremiumTitle"}>
                          {siteLang == "eng" ? languageText.premiumTitleEn : languageText.premiumTitle} (%)
                        </span>
                        <ReactTooltip
                          id={"dwTermPremiumTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.premiumTitleEn : languageText.premiumTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.premiumTitleTooltip1En : languageText.premiumTitleTooltip1}
                              <Link to="/eduhtml/premium">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.premiumTitleTooltip2En : languageText.premiumTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val premium">
                        {Livedata?.premium}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermExpiryTitle"}>
                          {siteLang == "eng" ? languageText.expiryTitleEn : languageText.expiryTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermExpiryTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.expiryTitleEn : languageText.expiryTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.expiryTitleTooltipEn : languageText.expiryTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val expiry">
                        {Livedata?.expiry}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermImpliedVolatilityTitle"}>
                          {siteLang == "eng" ? languageText.impliedVolatilityTitleEn : languageText.impliedVolatilityTitle} (%)
                        </span>
                        <ReactTooltip
                          id={"dwTermImpliedVolatilityTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>{siteLang == "eng" ? languageText.impliedVolatilityTitleEn : languageText.impliedVolatilityTitle}:</strong>
                              {siteLang == "eng" ? languageText.impliedVolatilityTitleTooltip1En : languageText.impliedVolatilityTitleTooltip1}
                              <Link to="/eduhtml/impliedvolatility">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.impliedVolatilityTitleTooltip2En : languageText.impliedVolatilityTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val implied_volatility">
                        {isNaN(Livedata?.implied_volatility) ? "N/A" : Number(Livedata?.implied_volatility).toFixed(1)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermLastTradingTitle"}>
                          {siteLang == "eng" ? languageText.lastTradingTitleEn : languageText.lastTradingTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermLastTradingTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.lastTradingTitleEn : languageText.lastTradingTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.lastTradingTitleTooltipEn : languageText.lastTradingTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val last_trading_date">
                        {Livedata?.last_trading_date}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermThetaTitle"}>
                          {siteLang == "eng" ? languageText.thetaTitleEn : languageText.thetaTitle} (%)
                        </span>
                        <ReactTooltip
                          id={"dwTermThetaTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>{siteLang == "eng" ? languageText.thetaTitleEn : languageText.thetaTitle}:</strong>
                              {siteLang == "eng" ? languageText.thetaTitleTooltip1En : languageText.thetaTitleTooltip1}
                              <Link to="/eduhtml/timedecay">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.thetaTitleTooltip2En : languageText.thetaTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val theta">
                        {Livedata?.theta}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermTimeMaturityTitle"}>
                          {siteLang == "eng" ? languageText.timeMaturityTitleEn : languageText.timeMaturityTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermTimeMaturityTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.timeMaturityTitleEn : languageText.timeMaturityTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.timeMaturityTitleTooltipEn : languageText.timeMaturityTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val daystomaturity">
                        {Livedata?.daystomaturity + " " + "days"}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermIntrinsicTitle"}>
                          {siteLang == "eng" ? languageText.intrinsicTitleEn : languageText.intrinsicTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermIntrinsicTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.intrinsicTitleEn : languageText.intrinsicTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.intrinsicTitleTooltip1En : languageText.intrinsicTitleTooltip1}
                              <Link to="/eduhtml/intrinsicvalueandtimevalue">
                                {siteLang == "eng" ? "" : languageText.tooltipClickHereEn}
                              </Link>
                              {siteLang == "eng" ? languageText.intrinsicTitleTooltip2En : languageText.intrinsicTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val intrinsic_value">
                        {Livedata?.intrinsic_value}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermRatioTitle"}>
                          {siteLang == "eng" ? languageText.ratioTitleEn : languageText.ratioTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermRatioTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.ratioTitleEn : languageText.ratioTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.ratioTitleTooltipEn : languageText.ratioTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val wrnt_per_share">
                        {Livedata?.wrnt_per_share}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermMoneynessTitle"}>
                          {siteLang == "eng" ? languageText.moneynessTitleEn : languageText.moneynessTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermMoneynessTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.moneynessTitleEn : languageText.moneynessTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.moneynessTitleTooltip1En : languageText.moneynessTitleTooltip1}
                              <Link to="/eduhtml/moneyness">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.moneynessTitleTooltip2En : languageText.moneynessTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className={Livedata?.moneyness == "ITM" ? "val mny up" : Livedata?.moneyness == "OTM" ? "val mny down" : "val mny"}>
                        {Livedata?.moneyness_c}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-closed table-striped hidden-md hidden-lg">
                  <tbody>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermUnderlyingTitle"}>
                          {siteLang == "eng" ? languageText.underlyingTitleEn : languageText.underlyingTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermUnderlyingTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.underlyingTitleEn : languageText.underlyingTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.underlyingTitleTooltipEn : languageText.underlyingTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val underlying_ticker">
                        {Livedata?.underlying_ticker}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermHistoricalTitle"}>
                          {siteLang == "eng" ? languageText.historicalTitleEn : languageText.historicalTitle} (%) (90 Day)
                        </span>
                        <ReactTooltip
                          id={"dwTermHistoricalTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.historicalTitleEn : languageText.historicalTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.historicalTitleTooltipEn : languageText.historicalTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val volatility_90d">
                        {Number(Livedata?.volatility_90d).toFixed(1)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermTypeTitle"}>
                          {siteLang == "eng" ? languageText.typeTitleEn : languageText.typeTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermTypeTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.typeTitleEn : languageText.typeTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.typeTitleTooltipEn : languageText.typeTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val type">
                        {Livedata?.type}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermIssuerTitle"}>
                          {siteLang == "eng" ? languageText.issuerTitleEn : languageText.issuerTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermIssuerTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.issuerTitleEn : languageText.issuerTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.issuerTitleTooltipEn : languageText.issuerTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val issuer_name">
                        {Livedata?.issuer == null ? "-" : Livedata?.issuer}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermMostRecentTitle"}>
                          {siteLang == "eng" ? languageText.mostRecentTitleEn : languageText.mostRecentTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermMostRecentTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.mostRecentTitleEn : languageText.mostRecentTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.mostRecentTitleTooltip1En : languageText.mostRecentTitleTooltip1}
                              <Link to="/eduhtml/howarewarrantspriced">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.mostRecentTitleTooltip2En : languageText.mostRecentTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val TRADE_DATE">
                        {Livedata?.TRADE_DATE == null ? "-" : Livedata?.TRADE_DATE}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermExpiryTitle"}>
                          {siteLang == "eng" ? languageText.expiryTitleEn : languageText.expiryTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermExpiryTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.expiryTitleEn : languageText.expiryTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.expiryTitleTooltipEn : languageText.expiryTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val expiry">
                        {Livedata?.expiry}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermLastTradingTitle"}>
                          {siteLang == "eng" ? languageText.lastTradingTitleEn : languageText.lastTradingTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermLastTradingTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.lastTradingTitleEn : languageText.lastTradingTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.lastTradingTitleTooltipEn : languageText.lastTradingTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val last_trading_date">
                        {Livedata?.last_trading_date}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermTimeMaturityTitle"}>
                          {siteLang == "eng" ? languageText.timeMaturityTitleEn : languageText.timeMaturityTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermTimeMaturityTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.timeMaturityTitleEn : languageText.timeMaturityTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.timeMaturityTitleTooltipEn : languageText.timeMaturityTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val daystomaturity">
                        {Livedata?.daystomaturity + " " + "days"}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermRatioTitle"}>
                          {siteLang == "eng" ? languageText.ratioTitleEn : languageText.ratioTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermRatioTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.ratioTitleEn : languageText.ratioTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.ratioTitleTooltipEn : languageText.ratioTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val wrnt_per_share">
                        {Livedata?.wrnt_per_share}
                      </td>
                    </tr>       
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermEffectiveGearingTitle"}>
                          {siteLang == "eng" ? languageText.effectiveGearingTitleEn : languageText.effectiveGearingTitle} (x)
                        </span>
                        <ReactTooltip
                          id={"dwTermEffectiveGearingTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.effectiveGearingTitleEn : languageText.effectiveGearingTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.effectiveGearingTitleTooltip1En : languageText.effectiveGearingTitleTooltip1}
                              <Link to="/eduhtml/effectivegearing">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.effectiveGearingTitleTooltip2En : languageText.effectiveGearingTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val effective_gearing">
                        {isNaN(Livedata?.effective_gearing) ? "N/A" : Number(Livedata?.effective_gearing).toFixed(1)}
                      </td>
                    </tr>         
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermDeltaTitle"}>
                          {siteLang == "eng" ? languageText.deltaTitleEn : languageText.deltaTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermDeltaTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>{siteLang == "eng" ? languageText.deltaTitleEn : languageText.deltaTitle}:</strong>
                              {siteLang == "eng" ? languageText.deltaTitleTooltip1En : languageText.deltaTitleTooltip1}
                              <Link to="/eduhtml/delta">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.deltaTitleTooltip2En : languageText.deltaTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta">
                        {isNaN(Number(Livedata?.delta)) ? "N/A" : Number(Livedata?.delta).toFixed(1)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermDeltaPerDwTitle"}>
                          {siteLang == "eng" ? languageText.deltaPerDwTitleEn : languageText.deltaPerDwTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermDeltaPerDwTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.deltaPerDwTitleEn : languageText.deltaPerDwTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.deltaPerDwTitleTooltip1En : languageText.deltaPerDwTitleTooltip1}
                              <Link to="/eduhtml/delta" target="_blank">
                                {siteLang == "eng"? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.deltaPerDwTitleTooltip2En : languageText.deltaPerDwTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta_per_wrnt">
                        {isNaN(Number(Livedata?.delta_dw)) ? "N/A" : Number(Livedata?.delta_dw).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermSensitivityTitle"}>
                          {siteLang == "eng" ? languageText.sensitivityTitleEn : languageText.sensitivityTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermSensitivityTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.sensitivityTitleEn : languageText.sensitivityTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.sensitivityTitleTooltipEn : languageText.sensitivityTitleTooltip}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val sensitivity">
                        {isNaN(Livedata?.sensitivity) ? "N/A" : Number(Livedata?.sensitivity).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermPremiumTitle"}>
                          {siteLang == "eng" ? languageText.premiumTitleEn : languageText.premiumTitle}(%)
                        </span>
                        <ReactTooltip
                          id={"dwTermPremiumTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.premiumTitleEn : languageText.premiumTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.premiumTitleTooltip1En : languageText.premiumTitleTooltip1}
                              <Link to="/eduhtml/premium">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.premiumTitleTooltip2En : languageText.premiumTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val premium">
                        {Livedata?.premium}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermImpliedVolatilityTitle"}>
                          {siteLang == "eng" ? languageText.impliedVolatilityTitleEn : languageText.impliedVolatilityTitle} (%)
                        </span>
                        <ReactTooltip
                          id={"dwTermImpliedVolatilityTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>{siteLang == "eng" ? languageText.impliedVolatilityTitleEn : languageText.impliedVolatilityTitle}:</strong>
                              {siteLang == "eng" ? languageText.impliedVolatilityTitleTooltip1En : languageText.impliedVolatilityTitleTooltip1}
                              <Link to="/eduhtml/impliedvolatility">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.impliedVolatilityTitleTooltip2En : languageText.impliedVolatilityTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val implied_volatility">
                        {isNaN(Livedata?.implied_volatility) ? "N/A" : Number(Livedata?.implied_volatility).toFixed(1)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermThetaTitle"}>
                          {siteLang == "eng" ? languageText.thetaTitleEn : languageText.thetaTitle} (%)
                        </span>
                        <ReactTooltip
                          id={"dwTermThetaTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>{siteLang == "eng" ? languageText.thetaTitleEn : languageText.thetaTitle}:</strong>
                              {siteLang == "eng" ? languageText.thetaTitleTooltip1En : languageText.thetaTitleTooltip1}
                              <Link to="/eduhtml/timedecay">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.thetaTitleTooltip2En : languageText.thetaTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val theta">
                        {Livedata?.theta}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermIntrinsicTitle"}>
                          {siteLang == "eng" ? languageText.intrinsicTitleEn : languageText.intrinsicTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermIntrinsicTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.intrinsicTitleEn : languageText.intrinsicTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.intrinsicTitleTooltip1En : languageText.intrinsicTitleTooltip1}
                              <Link to="/eduhtml/intrinsicvalueandtimevalue">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : ""}
                              </Link>
                              {siteLang == "eng" ? languageText.intrinsicTitleTooltip2En : languageText.intrinsicTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val intrinsic_value">
                        {Livedata?.intrinsic_value}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"dwTermMoneynessTitle"}>
                          {siteLang == "eng" ? languageText.moneynessTitleEn : languageText.moneynessTitle}
                        </span>
                        <ReactTooltip
                          id={"dwTermMoneynessTitle"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>
                                {siteLang == "eng" ? languageText.moneynessTitleEn : languageText.moneynessTitle}:
                              </strong>
                              {siteLang == "eng" ? languageText.moneynessTitleTooltip1En : languageText.moneynessTitleTooltip1}
                              <Link to="/eduhtml/moneyness">
                                {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                              </Link>
                              {siteLang == "eng" ? languageText.moneynessTitleTooltip2En : languageText.moneynessTitleTooltip2}
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className={Livedata?.moneyness == "ITM" ? "val mny up" : Livedata?.moneyness == "OTM" ? "val mny down" : "val mny"}>
                        {Livedata?.moneyness_c}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span className="index_iv small" style={{display: display}}>
                  {siteLang == "eng" ? languageText.impliedVolatilityNote1En : languageText.impliedVolatilityNote1}
                  <span className="implied_volatility2">
                    {indeIv}
                  </span>
                  {siteLang == "eng" ? languageText.impliedVolatilityNote2En : languageText.impliedVolatilityNote2}
                </span>
              </div>

              <div className="disclaimer hidden-xs hidden-sm">
                <p className="small">
                  {siteLang == "eng" ? languageText.dwPriceNoteEn : languageText.dwPriceNote}
                  <Link
                    className="text-lowercase text-bold"
                    style={{ color: "#0675C2" }}
                    target="_blank"
                    to="/eduhtml"
                  >
                    {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                  </Link>
                  <br />
                  <br />
                  {siteLang == "eng" ? languageText.underlyingPriceNoteEn : languageText.underlyingPriceNote}
                  <br />
                  <br />
                </p>
                <hr className="hr-hmgbm" />
                <p className="small">
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
              </div>

              <div className="panel-group visible-xs-block visible-sm-block" id="defination">
                <div className="panel panel-default">
                  <div className="panel-heading" onClick={disclaimerDefinationFun}>
                    <button>
                      <span className="pull-right">
                        <img src="/img/bg/questionMark.png" alt="questionMark" />
                      </span>
                      <div id="collapse_title3" className="panel-title text-center color-primary">
                        {siteLang == "eng" ? languageText.definitionDisclaimerEn : languageText.definitionDisclaimer}
                      </div>
                    </button>
                  </div>
                  <div id="collapse_dwcompare3" className={`panel-collapse collapse ${disclaimerDefination}`}>
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.dwSymbolTableEn : languageText.dwSymbolTable}</td>
                          <td>
                            {siteLang == "eng" ? languageText.dwSymbolTableTooltip1En : languageText.dwSymbolTableTooltip1} 
                            <Link target="_blank" to="/eduhtml/howarewarrantsnamed" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}  
                            </Link> 
                            {siteLang == "eng" ? languageText.dwSymbolTableTooltip2En : languageText.dwSymbolTableTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.underlyingTitleEn : languageText.underlyingTitle}</td>
                          <td>{siteLang == "eng" ? languageText.underlyingTitleTooltipEn : languageText.underlyingTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.typeTitleEn : languageText.typeTitle}</td>
                          <td>{siteLang == "eng" ? languageText.typeTitleTooltipEn : languageText.typeTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.issuerTitleEn : languageText.issuerTitle}</td>
                          <td>{siteLang == "eng" ? languageText.issuerTitleTooltipEn : languageText.issuerTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.mostRecentTitleEn : languageText.mostRecentTitle}</td>
                          <td>
                            {siteLang == "eng" ? languageText.mostRecentTitleTooltip1En : languageText.mostRecentTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/marketmakers" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link> 
                            {siteLang == "eng" ? languageText.mostRecentTitleTooltip2En : languageText.mostRecentTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.expiryTableEn : languageText.expiryTable}</td>
                          <td>{siteLang == "eng" ? languageText.expiryTableTooltipEn : languageText.expiryTableTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.lastTradingTitleEn : languageText.lastTradingTitle}</td>
                          <td>{siteLang == "eng" ? languageText.lastTradingTitleTooltipEn : languageText.lastTradingTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.historicalTitleEn : languageText.historicalTitle}</td>
                          <td>{siteLang == "eng" ? languageText.historicalTitleTooltipEn : languageText.historicalTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.timeMaturityTitleEn : languageText.timeMaturityTitle}</td>
                          <td>{siteLang == "eng" ? languageText.timeMaturityTitleTooltipEn : languageText.timeMaturityTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.ratioTitleEn : languageText.ratioTitle}</td>
                          <td>{siteLang == "eng" ? languageText.ratioTitleTooltipEn : languageText.ratioTitleTooltip}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.effectiveGearingTitleEn : languageText.effectiveGearingTitle} (x)</td>
                          <td>
                            {siteLang == "eng" ? languageText.effectiveGearingTitleTooltip1En : languageText.effectiveGearingTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/marketmakers" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link> 
                            {siteLang == "eng" ? languageText.effectiveGearingTitleTooltip2En : languageText.effectiveGearingTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.deltaTitleEn : languageText.deltaTitle}</td>
                          <td>
                            {siteLang == "eng" ? languageText.deltaTitleTooltip1En : languageText.deltaTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/marketmakers" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link> 
                            {siteLang == "eng" ? languageText.deltaTitleTooltip2En : languageText.deltaTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.deltaPerDwTitleEn : languageText.deltaPerDwTitle}</td>
                          <td>
                            {siteLang == "eng" ? languageText.deltaPerDwTitleTooltip1En : languageText.deltaPerDwTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/marketmakers" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link> 
                            {siteLang == "eng" ? languageText.deltaPerDwTitleTooltip2En : languageText.deltaPerDwTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.sensitivityTitleEn : languageText.sensitivityTitle}</td>
                          <td>{siteLang == "eng" ? languageText.sensitivityTitleTooltip1En : languageText.sensitivityTitleTooltip1}</td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.premiumTitleEn : languageText.premiumTitle} (%)</td>
                          <td>
                            {siteLang == "eng" ? languageText.premiumTitleTooltip1En : languageText.premiumTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/howarewarrantspriced" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.premiumTitleTooltip2En : languageText.premiumTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.impliedVolatilityTitleEn : languageText.impliedVolatilityTitle} (%)</td>
                          <td>
                            {siteLang == "eng" ? languageText.impliedVolatilityTitleTooltip1En : languageText.impliedVolatilityTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/howarewarrantspriced" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.impliedVolatilityTitleTooltip2En : languageText.impliedVolatilityTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.thetaTitleEn : languageText.thetaTitle} (%)</td>
                          <td>
                            {siteLang == "eng" ? languageText.thetaTitleTooltip1En : languageText.thetaTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/howarewarrantspriced" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.thetaTitleTooltip2En : languageText.thetaTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.intrinsicTitleEn : languageText.intrinsicTitle}</td>
                          <td>
                            {siteLang == "eng" ? languageText.intrinsicTitleTooltip1En : languageText.intrinsicTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/howarewarrantspriced" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : ""}
                            </Link>
                            {siteLang == "eng" ? languageText.intrinsicTitleTooltip2En : languageText.intrinsicTitleTooltip2}
                          </td>
                        </tr>
                        <tr>
                          <td>{siteLang == "eng" ? languageText.moneynessTitleEn : languageText.moneynessTitle}</td>
                          <td>
                            {siteLang == "eng" ? languageText.moneynessTitleTooltip1En : languageText.moneynessTitleTooltip1}
                            <Link target="_blank" to="/eduhtml/howarewarrantspriced" className="text-lowercase">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.moneynessTitleTooltip2En : languageText.moneynessTitleTooltip2}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="small">
                        {siteLang == "eng" ? languageText.dwPriceNoteEn : languageText.dwPriceNote}
                        <Link className="text-lowercase" style={{color: "#0675C2"}} target="_blank" to="/eduhtml">
                          {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                        </Link>.
                        <br />
                        <br />
                        {siteLang == "eng" ? languageText.underlyingPriceNoteEn : languageText.underlyingPriceNote}
                        <br />
                        <br />
                    </p>
                    <hr />
                    <p className="small">
                      {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                    </p> 
                  </div>
                </div>
              </div>
              
            </div>
           
          </div>

          <div className="col-md-4 hidden-sm hidden-xs">
            <LiveMatrixSideBars />
            <WarrantSearchSideBars />
            {/* <WarrantCalculatorSideBars /> */}
            <AdvertiseMentSidebar />
          </div>
        </div>
      </div>

      <WarrantTermsModal
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </div>
  );
};

export default WarrantTerms;
