export const Base_Url = ""//"https://t12a.trkd-hs.com" 
// export const Base_Url = "https://www.thaidw.com" 

export const Api_MqTh = "/apimqth/" // mqmy_aws
export const COMPLETE_URL = Base_Url + Api_MqTh;

export const config ={

homeJSON: `${Base_Url}${Api_MqTh}HomeJSON`,
getHighlight: `${Base_Url}${Api_MqTh}NewsDataJSON`, 
marketDailyHighLight: `${Base_Url}${Api_MqTh}NewsTableServlet`,
marketupdateStockNews: `${Base_Url}${Api_MqTh}NewsTableServlet`,
marketfastTrack: `${Base_Url}${Api_MqTh}FastTrackJSON`,
marketfaq: `${Base_Url}${Api_MqTh}FAQDataJSON`,
marketansFaq: `${Base_Url}${Api_MqTh}FAQDataJSON`,
marketSP500Market: `${Base_Url}${Api_MqTh}NewsTableServlet`,
marketDailHsiMarket: `${Base_Url}${Api_MqTh}NewsTableServlet`,
marketDailyCommentryMarket: `${Base_Url}${Api_MqTh}NewsTableServlet`,
marketOvernightMarket:  `${Base_Url}${Api_MqTh}NewsTableServlet`,
subscription: `${Base_Url}${Api_MqTh}registration`,
turnOverDistribution: `${Base_Url}${Api_MqTh}MarketDataJSON`,
marketTurnOver: `${Base_Url}${Api_MqTh}MarketDataJSON`,
homeWarrantSearch: `${Base_Url}${Api_MqTh}ScreenerJSONServlet`,
photoGallery: `${Base_Url}${Api_MqTh}PhotoGalleryJSON`,
getPhotoUrl:`${Base_Url}${Api_MqTh}PhotoGalleryJSON`,
actvitie:`${Base_Url}${Api_MqTh}ActivitiesTableServlet`,
termAlert:`${Base_Url}${Api_MqTh}alert`,
homeLiveIndexFuturesSymbol:`${Base_Url}${Api_MqTh}SymbolsJSON`,
dwRanking: `${Base_Url}${Api_MqTh}UnderlyingRankingJSON`,
warrantComparison: `${Base_Url}${Api_MqTh}ComparisonDataJSON`,
DWTipsTableServlet: `${Base_Url}${Api_MqTh}DWTipsTableServlet`,
TipsDataJSON: `${Base_Url}${Api_MqTh}TipsDataJSON`,
dwRanking1: `${Base_Url}${Api_MqTh}UnderlyingRankingJSON`,
seminar:`${Base_Url}${Api_MqTh}SeminarJSON`,
seminar1:`${Base_Url}${Api_MqTh}/seminarreg`,
gameservlet:`${Base_Url}${Api_MqTh}/GameServlet`,
dwterms:`${Base_Url}${Api_MqTh}/ComparisonDataJSON`,
LiveMatrix:`${Base_Url}${Api_MqTh}LiveMatrixJSON`,
ActivitesDates:`${Base_Url}${Api_MqTh}ActivitiesDataJSON`,
marketTurnOverList:`${Base_Url}${Api_MqTh}MarketDataJSON`,
livenewsdata1:`${Base_Url}${Api_MqTh}LiveIndexJSON`,
livenewsdata2:`${Base_Url}${Api_MqTh}LiveIndexJSON?_t`,
homeBanner: `${Base_Url}${Api_MqTh}HomeBannerJSON`,
homePopup :`${Base_Url}${Api_MqTh}photo?popupHomepage=1`,
PopupHomepageJSON :`${Base_Url}${Api_MqTh}PopupHomepageJSON`,
advImgData: `${Base_Url}${Api_MqTh}photo`,
lastView:`${Base_Url}${Api_MqTh}MarketDataJSON`,
homeNewsBanner:`${Base_Url}${Api_MqTh}imagefetch`,
topTradedStock:`${Base_Url}${Api_MqTh}MarketDataJSON`,
addvertisementApi:`${Base_Url}${Api_MqTh}showVideo`,
getUnderlying: `${Base_Url}${Api_MqTh}GetUnderlyingData`,
dailyMarketAnalysisJSON: `${Base_Url}${Api_MqTh}DailyMarketAnalysisJSON`,
unSubscribeNewsLetter: `UnsubscribeServlet`,
pageSearch: `${Base_Url}${Api_MqTh}PageSearchJSON`,
topTradedJSON:`${Base_Url}${Api_MqTh}TopTradedJSON`
}
